<template>
  <v-app id="app-content">
    <v-app-bar app clipped-left dark color="primary">
      <v-toolbar-title class="mx-auto">
        <img src="/images/logo-fyzioklinika.svg" alt="Logo fyzioklinika" style="max-height: 52px" />
      </v-toolbar-title>
    </v-app-bar>

    <v-content>
      <router-view></router-view>
    </v-content>

    <v-footer app color="white">
      <span>
        &copy; 2020
        <a href="https://itband.eu" target="_blank">it_BAND</a>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    primaryDrawer: {
      mini: false,
    },
  }),
};
</script>