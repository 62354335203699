<template>
  <div>
    <v-container>
      <v-row v-if="settings">
        <v-col cols="12" md="8" lg="6" xl="4" offset-md="2" offset-lg="3" offset-xl="4">
          <h1 class="h2 itb-headline mb-0 text-center">
            {{ settings.event_name }}
            <span class="itb-text-muted">{{ settings.event_date }}</span>
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <div class="itb-action-buttons mt-3" v-if="settings">
      <v-container class="p-0">
        <v-row>
          <v-col
            cols="12"
            md="8"
            lg="6"
            xl="4"
            offset-md="2"
            offset-lg="3"
            offset-xl="4"
            class="px-4"
          >
            <v-btn
              class="float-left"
              color="secondary"
              small
              @click="shownTab = 1; teamId = null"
              v-if="shownTab == 2"
            >
              <v-icon class="mr-1 text-white">mdi-arrow-left</v-icon>
              {{ $t("results.overall_evaluation") }}
            </v-btn>
            <v-btn class="float-right" color="success" small @click="refreshResults()">
              <v-icon class="mr-1 text-white">mdi-reload</v-icon>
              {{ $t("results.reload_results") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container>
      <v-row v-if="settings">
        <v-col cols="12" md="8" lg="6" xl="4" offset-md="2" offset-lg="3" offset-xl="4">
          <div v-if="shownTab == 1">
            <h2 class="h4 itb-headline mt-5 text-center">{{ $t("results.overall_evaluation") }}</h2>

            <v-alert
              class="mb-3 mt-5 text-center p-1"
              text
              outlined
              color="blue darken-4"
              style="font-size: 100%"
            >
              Pořadí týmů získáte po dokončení {{ settings.counted_stands }}-ti stanovišť.
              <br />Body ze stanoviště počítáme dle vzorce:
              <br />
              {průměr bodů dětí} PLUS {průměr bodů dospělých}, kteří úkol splnili.
              <br />Do celkového pořadí jsou započteny body pouze z 10 nejlepších stanovišť.
            </v-alert>

            <v-card class="mt-5" :loading="isAllTeamsResultsLoading">
              <v-card-text class="p-0">
                <div class="itb-search-in-table d-flex">
                  <v-text-field
                    v-model="filterString"
                    :label="$t('results.filter_by_team_name')"
                    solo
                    dense
                    prepend-icon="mdi-magnify"
                    clearable
                    hide-details
                  ></v-text-field>
                </div>
                <v-simple-table class="itb-densed-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="px-2">{{ $t("results.rank") }}</th>
                        <th class="text-center px-2">{{ $t("results.team") }}</th>
                        <th class="text-center px-2">{{ $t("results.status") }}</th>
                        <th class="text-center px-2">{{ $t("results.points") }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(team, index) in filteredRows" :key="`team-${index}`">
                        <td class="px-2">
                          {{ team.rank }}
                          <v-icon
                            v-if="team.rank > 0 && team.rank <= 3"
                            :style="'color: ' + getRankColor(team.rank)"
                          >mdi-crown</v-icon>
                        </td>
                        <td class="text-center px-2">{{ team.name }}</td>
                        <td class="text-center px-2">
                          <v-chip
                            small
                            label
                            :color="getStatusColor(team.status)"
                            text-color="white"
                            class="px-2"
                          >{{ getStatusLabel(team.status) }}</v-chip>
                        </td>
                        <td class="text-center px-2">{{ team.total }}</td>
                        <td class="p-0" style="width: 20px">
                          <v-btn icon color="success" v-on:click="showTeamResults(team.id)">
                            <v-icon>mdi-account-search</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>

          <div v-if="team && shownTab == 2">
            <h2 class="h4 itb-headline mt-5 text-center">{{ $t("results.basic_info") }}</h2>
            <v-card class="mb-3" :loading="isTeamResultsLoading">
              <v-card-text class="p-0">
                <v-row>
                  <v-col class="text-center" cols="4">
                    <div class="mb-2">
                      <v-icon color="purple">mdi-account-group</v-icon>
                    </div>
                    <div
                      class="purple--text font-weight-bold mb-1"
                      style="font-size: 1.7rem;"
                    >{{ team.name }}</div>
                    <div>{{ $t("results.team") }}</div>
                  </v-col>
                  <v-col class="text-center" cols="4">
                    <div class="mb-2">
                      <v-icon :style="'color: ' + getRankColor(team.rank)">mdi-crown</v-icon>
                    </div>
                    <div
                      class="font-weight-bold mb-1"
                      :style="'color: ' + getRankColor(team.rank) + '; font-size: 1.7rem'"
                    >{{ team.rank }}</div>
                    <div>{{ $t("results.rank") }}</div>
                  </v-col>
                  <v-col class="text-center" cols="4">
                    <div class="mb-2">
                      <v-icon color="green">mdi-counter</v-icon>
                    </div>
                    <div
                      class="green--text font-weight-bold mb-1"
                      style="font-size: 1.7rem;"
                    >{{ team.total }}</div>
                    <div>{{ $t("results.total") }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <h2 class="h4 itb-headline mt-5 text-center">{{ $t("results.stands_points") }}</h2>

            <v-alert
              class="mb-3 mt-5 text-center p-1"
              text
              outlined
              color="blue darken-4"
              style="font-size: 100%"
            >
              Pořadí týmů získáte po dokončení {{ settings.counted_stands }}-ti stanovišť.
              <br />Body ze stanoviště počítáme dle vzorce:
              <br />
              {průměr bodů dětí} PLUS {průměr bodů dospělých}, kteří úkol splnili.
              <br />Do celkového pořadí jsou započteny body pouze z 10 nejlepších stanovišť.
            </v-alert>

            <v-card :loading="isTeamResultsLoading">
              <v-card-text class="p-0">
                <v-simple-table class="itb-densed-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="px-2">{{ $t("results.stand") }}</th>
                        <th
                          class="text-center px-2"
                          v-for="member in team.teamMembers"
                          :key="member.id"
                        >{{ member.name }}</th>
                        <th class="text-center px-2">{{ $t("results.average") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(stand, index) in team.stands" :key="`stand-${index}`">
                        <td class="px-2">{{ index }}. {{ stand.name }}</td>
                        <td
                          v-for="member in team.teamMembers"
                          :key="member.id"
                          class="text-center px-2"
                        >{{ stand.members_points && member.id in stand.members_points ? stand.members_points[member.id] : '-' }}</td>
                        <td
                          class="text-center px-2"
                          style="background: #E8F5E9"
                        >{{ stand.teamPoints }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <SnackMessage ref="SnackMessage" />
  </div>
</template>

<script>
import SnackMessage from "../components/SnackMessage";

export default {
  data() {
    return {
      shownTab: 1,
      teamId: null,
      team: null,
      allTeamsResults: [],
      settings: null,
      isAllTeamsResultsLoading: true,
      isTeamResultsLoading: true,
      filterString: "",
    };
  },

  components: {
    SnackMessage,
  },

  created() {
    this.getAllTeamsResults();
    this.getPublicSettings();
  },

  computed: {
    filteredRows() {
      return this.allTeamsResults.filter((team) => {
        const name = team.name.toLowerCase();
        const searchTerm = this.filterString
          ? this.filterString.toLowerCase()
          : "";

        return name.includes(searchTerm);
      });
    },
  },

  methods: {
    getStatusColor(status) {
      switch (status) {
        case 3:
          return "success";
        case 4:
          return "red";
      }
    },
    getStatusLabel(status) {
      switch (status) {
        case 3:
          return this.$t("results.status_types.on_track");
        case 4:
          return this.$t("results.status_types.finished");
      }
    },
    refreshResults() {
      this.isAllTeamsResultsLoading = true;
      this.getAllTeamsResults();

      if (this.teamId) {
        this.showTeamResults(this.teamId);
        this.isTeamResultsLoading = true;
      }
    },
    getRankColor(rank) {
      if (rank == 1) {
        return "#FFC107";
      } else if (rank == 2) {
        return "#78909C";
      } else if (rank == 3) {
        return "#F4511E";
      } else {
        return "#BDBDBD";
      }
    },
    async showTeamResults(teamId) {
      await axios
        .get(`teamResults/${teamId}`)
        .then((response) => {
          this.team = response.data.team;
          this.shownTab = 2;
          this.teamId = teamId;
          this.isTeamResultsLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    async getAllTeamsResults() {
      await axios
        .get("/allTeamsResults")
        .then((response) => {
          this.allTeamsResults = response.data;
          this.isAllTeamsResultsLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    async getPublicSettings() {
      await axios
        .post("/getPublicSettings", [
          "event_name",
          "event_date",
          "counted_stands",
        ])
        .then((response) => {
          this.settings = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>