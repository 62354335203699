require('./bootstrap');
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'

window.Vue = require('vue');

import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from 'vuetify'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(Vuetify)

Vue.component('app-container', require('./public-results/views/AppContainer.vue').default);

import PublicResultsContainer from './public-results/views/PublicResultsContainer.vue';
import PublicResults from './public-results/views/PublicResults.vue';

const routes = [
    {
        path: '/vysledky', component: PublicResultsContainer,
        children: [
            { path: '', name: 'publicResults', component: PublicResults },

        ],
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

function loadLocalMessages() {
    const locales = require.context(
        "../../src/locales/public-results",
        true,
        /[A-za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "cs", //Set locale
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en", //Set fallback locale
    messages: loadLocalMessages() //Set locale messages
})

const opts = {
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    }
}

export default new Vuetify(opts)

const app = new Vue({
    el: "#app",
    vuetify: new Vuetify(),
    i18n,
    router
});